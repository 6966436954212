import { route } from "helpers/routeHelper";
import Meeting from "pages/Meeting/index";

const publicRoutes = [

];

const protectedRoutes = [
  { path: route('start_meeting'), component: Meeting },
];

export { publicRoutes, protectedRoutes }
