import React from "react"
import PropTypes from "prop-types"

const Authmiddleware = ({
  component: Component,
  componentProps,
  layout: Layout,
  isProtected,
}) => {

  return <Layout>
    <Component {...componentProps} />
  </Layout>
}

Authmiddleware.propTypes = {
  isProtected: PropTypes.bool,
  component: PropTypes.any,
  componentProps: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
