import React from 'react';
import { useAuth } from './auth';

const AccessContext = React.createContext();

const AccessProvider = props => {

  const { user: authUser } = useAuth();

  const isAdmin = () => authUser && authUser.isAdmin();
  const isScheduler = () => authUser && authUser.isScheduler();
  const isDealerGroupManager = () => authUser && authUser.isDealerGroupManager();
  const isDealerRegionalManager = () => authUser && authUser.isDealerRegionalManager();
  const isDealerStoreManager = () => authUser && authUser.isDealerStoreManager();
  const isDealerFinanceManager = () => authUser && authUser.isDealerFinanceManager();
  const isDealerSalesManager = () => authUser && authUser.isDealerSalesManager();
  const isDealerUpperManager = () => authUser && authUser.isDealerUpperManager();
  const isDealerLowerManager = () => authUser && authUser.isDealerLowerManager();
  const isDealerManager = () => authUser && authUser.isDealerManager();
  const isDealerStoreUser = () => authUser && authUser.isDealerStoreUser();

  const isAdminType = () => authUser && authUser.isAdminType();
  const isDealerType = () => authUser && authUser.isDealerType();

  const isGranted = (attribute, subject) => {
    switch (attribute) {
      default:
        return false;
    }
  }

  const isNotGranted = (attribute, subject) => !isGranted(attribute, subject);

  const isAnyGranted = (attributes, subject) => attributes.some(attr => isGranted(attr, subject));

  const contextValue = {
    isGranted,
    isNotGranted,
    isAnyGranted,
    isAdmin,
    isScheduler,
    isDealerGroupManager,
    isDealerRegionalManager,
    isDealerStoreManager,
    isDealerFinanceManager,
    isDealerSalesManager,
    isDealerUpperManager,
    isDealerLowerManager,
    isDealerManager,
    isDealerStoreUser,
    isAdminType,
    isDealerType,
  };

  return <AccessContext.Provider value={contextValue} {...props} />
}

// helper hook that makes context data available
export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;