import { isObject, valueIsEmpty } from './utilHelper';

const routes = {
  start_meeting: '/r/:roomName',
};

export const route = (name, params, query) => {
  let route = routes[name];
  if (!route) {
    return;
  }
  if (!valueIsEmpty(params)) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (valueIsEmpty(param)) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (valueIsEmpty(params[key])) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (valueIsEmpty(params)) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};