import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import AuthContext from "context/auth";
import AccessContext from "context/access";

const app = (
  <Provider store={store}>
    <AuthContext>
      <AccessContext>
        <App />
      </AccessContext>
    </AuthContext>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);
