import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { JaaSMeeting } from '@jitsi/react-sdk';
import { checkMeeting, endMeeting, updateDuration, startMeeting, participantJoined, participantLeft } from 'helpers/backendHelper';
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import config from 'config';
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-simple.svg";
import Error from 'pages/Error';

let isModerator = false;
let participantName = "";

const Meeting = () => {

  const { roomName } = useParams();

  const DURATION_UPDATE_INTERVAL = 30000;

  // read query string
  const queryParams = new URLSearchParams(window.location.search);

  const [isMeetingActive, setIsMeetingActive] = useState(false);
  const [jwt, setJwt] = useState('');
  const [meetingId, setMeetingId] = useState(null);
  const [meetingEnded, setMeetingEnded] = useState(false);
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [meetingError, setMeetingError] = useState(null);

  useEffect(() => {
    checkMeeting(roomName).then(res => {
      if (res.id) {
        setJwt(res.token);
        setIsMeetingActive(true);
        setMeetingId(res.id)
      }
    }).catch(ex => {
      setMeetingError(ex);
    });
  }, []);

  useEffect(() => {
    if (meetingEnded) {
      // when meeting has ended, perform different actions for each type of participant
      if (isModerator) {
        endMeeting(meetingId);
        clearTimeout(updateTimeout);
        setUpdateTimeout(null)
      } else {
        participantLeft(meetingId, participantName);
      }
    }
  }, [meetingEnded]);

  const handleApiReady = apiObj => {
    apiObj.addEventListener('videoConferenceJoined', function (event) {
      participantName = event.displayName;
    });
    apiObj.addEventListener('participantRoleChanged', function (event) {
      if (event.role === 'moderator') {
        // make the necessary actions for moderator here
        isModerator = true;
        startMeeting(meetingId);
        durationUpdateCron(meetingId);
        apiObj.executeCommand('toggleLobby', true);
        apiObj.addEventListener('videoConferenceLeft', function (event) {
          // perform actions in use effect because sometimes this listener is called twice
          setMeetingEnded(true);
        });
      }
    });
    apiObj.addEventListener('subjectChange', function (event) {
      // event occurs after user joined, and we know if he's moderator or not
      if (!isModerator) {
        // make the necessary actions for participant(not moderator) here
        participantJoined(meetingId, participantName);
        apiObj.addEventListener('videoConferenceLeft', function (event) {
          // perform actions in use effect because sometimes this listener is called twice
          setMeetingEnded(true);
        });
      }
    });
    apiObj.addEventListener('readyToClose', function (event) {
      setIsMeetingActive(false);
    });
  }

  const durationUpdateCron = (meetingId) => {
    setUpdateTimeout(setTimeout(() => {
      updateDuration(meetingId);
      durationUpdateCron(meetingId)
    }, DURATION_UPDATE_INTERVAL))
  }

  return <div className="jitsi-meet-wrapper">
    {isMeetingActive && <JaaSMeeting
      appId={config.JAAS_APP_ID}
      jwt={jwt}
      roomName={roomName}
      userInfo={{ displayName: queryParams.get('name') }}
      onApiReady={externalApi => handleApiReady(externalApi)}
      configOverwrite={{
        brandingRoomAlias: roomName
      }}
    />}
    {!isMeetingActive && !meetingError && <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={8}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Secure Video Conferencing</h5>
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <a href="https://www.mavsign.com" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} height="44" />
                        </span>
                      </div>
                    </a>
                    <div className="text-center font-weight-semibold">
                      <p>Connect and collaborate from anywhere.</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} {config.APP_TITLE}. v {config.APP_VERSION}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>}
    {!!meetingError && <Error error={meetingError} title404="Meeting not found" />}
  </div>
}

export default Meeting;
