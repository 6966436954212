import { get, put, post } from "./apiHelper";

// User

export const getAuthUser = () => get('/me');

export const checkMeeting = (uuid) => get(`/meeting/${uuid}`);

export const updateDuration = (id) => put(`/meeting/${id}/update-duration`)

export const startMeeting = (id) => put(`/meeting/${id}/start`)

export const endMeeting = (id) => put(`/meeting/${id}/end`)

export const participantJoined = (id, participantName) => post(`/meeting/${id}/participant-joined`, { participantName })

export const participantLeft = (id, participantName) => post(`/meeting/${id}/participant-left`, { participantName })