import { takeEvery, put, call } from "redux-saga/effects"

import { GET_AUTH_USER } from "./actionTypes"
import { getAuthUserOk, getAuthUserErr } from "./actions"

import {
  getAuthUser,
} from "helpers/backendHelper";

/********** LOGIN **********/

function* onGetAuthUser() {
  try {
    const response = yield call(getAuthUser);
    yield put(getAuthUserOk(response));
  } catch (error) {
    yield put(getAuthUserErr(error));
  }
}

function* authSaga() {
  yield takeEvery(GET_AUTH_USER, onGetAuthUser);
}

export default authSaga;
