import PropTypes from 'prop-types'
import React from "react"

import { Routes, Route, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// Import Routes all
import { protectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "routes/route"

// layouts Format
import Layout from "components/Layout"
import NotFound from "pages/Error/NotFound"

// Import scss
import "./assets/scss/theme.scss"

const App = () => {

  return (
    <React.Fragment>
      <ReactNotifications />
      <Router>
        <Routes>
          {publicRoutes.map((route, idx) => <Route key={idx} path={route.path} element={<Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            componentProps={route.props}
            isProtected={false}
          />} />)}
          {protectedRoutes.map((route, idx) => <Route key={idx} path={route.path} element={<Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            componentProps={route.props}
            isProtected={true}
          />} />)}
          <Route element={<NotFound title="Page not found" />} />
        </Routes>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
